import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import ProductView from './product-view'

const ProductController = (props) => {
  const { slug } = props
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState({})
  const { i18n } = useTranslation()
  const getListProductsByCategory = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'product',
      params: {
        slug,
        lang: i18n.language,
      },
    })
      .then(({ data }) => {
        setProduct(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getListProductsByCategory()
  }, [slug])

  const viewProps = {
    data: product,
    loading,
  }
  return <ProductView {...viewProps} />
}

export default ProductController
